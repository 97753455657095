.checkTemporary {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    .form-check-input {
        vertical-align: baseline !important;
    }

    .checkTemporaryBox {}

    .checkTemporaryText {
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: #4B5168;
    }

}