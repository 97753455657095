.resourceBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  .resourceTopBar {
    display: flex;
    flex-direction: row;
    height: 56px;
    border-bottom: 1px solid #C6C8CE;
    padding: 16px;
    justify-content: space-between;
    align-items: center;

    .search-input {
      width: 300px !important;
    }

    .app-search .clear {
      height: 12px;
      width: 12px;

    }

    .barArrowDiv {
      display: flex;
      width: 36px;
      height: 36px;
      padding: 11px 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      &:hover {
        background-color: #F3F4F5;
        cursor: pointer;
      }

      .iconArrow {
        width: 16px;
        height: 16px;
      }

    }
  }

  .resourceBarContent {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 162px;
    overflow-x: auto;
    overflow-y: hidden;

    .emptyBar {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .source-list {
    display: flex;
    flex-direction: row;
    gap: 8px;



  }


}