.displayStaticFieldInRow {
    display: flex;
    flex-direction: row;

    .staticValueCopyField {
        .form-control {
            height: 36px !important;
            border: 1px solid #C6C8CE !important;
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            box-shadow: inset 0px 2px 4px rgba(31, 39, 71, 0.1) !important;
            padding: 8px 16px 8px 16px !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: #1F2747 !important;

            &:hover {
                border-color: #4B5168 !important;

            }

            &:disabled{
                box-shadow: none !important;
                background-color: #f3f4f5 !important;
            }

            &:focus {
                border-color: #6C89E8 !important;
            }

            &:disabled:hover {
                border-color: #C6C8CE !important;
            }
        }
    }

    .copyDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 11px 15px;
        gap: 10px;
        width: 36px;
        height: 36px;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px !important;
        border: 1px solid #C6C8CE !important;
        border-left: none !important;

        &:hover {
            background-color: #F3F4F5 !important;
            cursor: pointer;
        }

        .copyIcon {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #1F2747;
            width: 16px;
            height: 16px;

        }
    }
}