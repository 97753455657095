.accountStatusDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 528px;

    .custom-toggle-switch-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
    }
}