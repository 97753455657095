.usefulCard {

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    isolation: isolate;

    min-width: 168px;
    width: 168px;
    max-width: 168px;

    min-height: 130px;
    height: 130px;
    max-height: 130px;

    background: #FFFFFF;

    border: 1px solid #C6C8CE;
    border-radius: 8px;

    cursor: pointer;

    &:hover {
        border-color: var(--userful-primary-light);
        filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14));
    }

    >.infoBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        isolation: isolate;

        width: 100%;
        height: 36px;

        border-bottom: 1px solid #C6C8CE;

        >.infoBarElements {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 4px 0px 8px;
            gap: 4px;

            width: 100%;
            height: 36px;
        }

    }



    >.contentBar {

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 58px;

        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        background: #F3F4F5;

        >.image {

            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.nameBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 8px;
    isolation: isolate;

    width: 100%;
    height: 36px;

    border-bottom: 1px solid #C6C8CE;

    >.nameBarText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: #4B5168;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis !important;

    }
}

$color-lgpurple-card: #9747FF;
$color-lgpurple-background-card: rgba(151, 71, 255, 0.20);
$color-blue-card: #234FDC;
$color-blue-background-card: #DAE2F9;

.layoutStyleCommon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
    border-radius: 8px;

}

.lightPurpleDiv {
    background: $color-lgpurple-background-card;
    color: $color-lgpurple-card;
}

.layoutSvgCommon {
    height: 16px;
    font-family: 'Font Awesome 6 Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

}

.blueDiv {
    background: $color-blue-background-card;
    color: $color-blue-card;
}

.lightPurpleInfo {
    @extend .layoutStyleCommon;
    @extend .lightPurpleDiv;

    >svg {
        @extend .layoutSvgCommon;
        color: $color-lgpurple-card;
    }
}

.blueInfo {
    @extend .layoutStyleCommon;
    @extend .blueDiv;
    margin: 0;

    >svg {
        @extend .layoutSvgCommon;
        color: $color-blue-card;
    }
}