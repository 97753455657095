.overallTableHead {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #C6C8CE;
    border-radius: 8px;
    border-bottom: 1px solid #C6C8CE;
    // width: 1040px;
    width: 100%;

    .tableHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 24px;
        gap: 8px;
        height: 40px;
        background: #F3F4F5;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .headText {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4B5168;
        }

    }

    .buttonDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 11px 15px;
        gap: 10px;
        width: 36px;
        height: 36px;

        &:hover {
            background-color: #F3F4F5;
            border-radius: 8px;
            cursor: pointer;
        }

        .iconDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 20px;
            height: 20px;
        }
    }

    .monitoring-table-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        table {
            table-layout: fixed;

            tr {
                height: 36px;
                align-items: center;
                display: flex;

                > :last-child {
                    display: flex;
                    flex-direction: row;
                    padding: 0px 24px;


                }

            }

            tbody {
                tr {
                    border-top: 1px solid var(--userful-border-secondary);

                    td {
                        padding: 0px 24px;
                        color: var(--userful-primary-dark);
                        word-break: break-all;

                        >&:first-child {
                            width: 200px;
                        }

                        .textOverall {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 0px 24px;
                            gap: 10px;



                            .textDiv {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                display: flex;
                                align-items: center;
                                color: #1F2747;
                            }
                        }


                    }

                }


            }

        }

    }
}