.actionButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 8px;
    border: 1px solid #C6C8CE;

    &:hover {
        cursor: pointer;
    }

    .actionButtonImageDiv {
        display: flex;
        padding: 30px 0px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid #c6c8ce;
        background-color: #F7F9FA;
        overflow: hidden;
        border-top-left-radius : 8px;
        border-top-right-radius: 8px;

        .actionButtonImage {
            display: flex;
            width: 30px;
            height: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                color: #1F2747;
                text-align: center;
                font-size: 24px;
                font-weight: 400;
            }
        }

    }

    .actionButtonTextDiv {
        display: flex;
        height: 50px;
        padding: 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--dark-400, #1F2747);
        text-align: center;
        font-size: 14px;
        font-weight: 500;

    }

}