.app-search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .search-input {
        height: 36px;
        border: 1px solid var(--userful-border-secondary);
        color: #4B5168;
        padding: 8px 42px;
        box-shadow: inset 0px 2px 4px rgb(31 39 71 / 10%);
        border-radius: 8px;

        &:hover {
            border: 1px solid #4B5168;
            outline: var(--userful-white);
        }

        &:focus {
            border: 1px solid #6C89E8;
            outline: var(--userful-white);
        }
    }

    .search-icon {
        position: absolute;
        left: 16px;
    }

    .go-icon {
        position: absolute;
        right: 16px;
        
    }

    .clearIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 2px;
        cursor: pointer;
    }

    .clear {
        width: 16px;
        height: 16px;

    }

    .clearHover:hover {

        cursor: pointer;

    }
}

.search {
    position: relative;
    top: -4px;
    left: 0px;
}