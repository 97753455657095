.addBorder {
    border: 1px solid #C6C8CE;
    border-radius: 8px;

    .sortByFont .dropdown-item {
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #1f2747;
    }
}


.layoutButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .layoutText {
        display: flex;
        flex-direction: column;
        margin: auto 0;
        font-size: 14px;
        font-weight: 400;
        align-items: center;
        text-align: center;
        justify-content: center;

    }

    .subnav-header-info {
        display: flex;

        >button {
            height: 36px;
            width: 36px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border: 1px solid #C6C8CE;
            background-color: #ffffff;
            svg{
                height: 16px;
                width: 16px;
            }

            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px
            }

            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }

        }

        .active {
            background: var(--userful-bg-primary-light) !important;
            border: 1px solid var(--userful-primary-blue) !important;
            color: var(--userful-primary-blue) !important;
        }
    }

}