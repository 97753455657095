.bar {
    width: 100%;
    // background-color: #3498db;  // You can choose any color
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height:30px;
    margin-bottom: 6px;
    border-bottom: 1px solid var(--light-500, #C6C8CE);
    gap:6px;
    padding-bottom: 6px;

    .button-secondary {
        background: #ffffff;
        border-radius: 4px;
        color: #1F2747;
        border: 1px solid #c6c8ce5b;
        height: 35px;
        padding: 0px 16px;
        // width: 20px;
        // padding-bottom:6px;
        font-weight: 600;

        &:hover {
            background: #a7bdff;
        }
    }
}
