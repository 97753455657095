.overallUserForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 36px;
    gap: 36px;
    min-height: calc(100vh - 56px);
}

.userDetailsDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.namesDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 528px;
}

.button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    height: 56px;
    background: #fff;
    border-top: 1px solid #c6c8ce;
    position: sticky;
    padding: 0px 36px;
    width: 600px;
    bottom: 0px;
    right: 0px;
}