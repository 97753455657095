.controlBar {
    display: flex;
    flex-direction: column;
    width: 468px;

    .controlBarConferenceText {
        color: #4B5168;
        font-size: 14px;
        font-weight: 500;
        display: flex;
    }

    .conrolBarTop {
        display: flex;
        flex-direction: row;
        height: 57px;
        padding: 14px 16px;
        align-items: center;
        align-self: stretch;
        border-bottom: 1px solid #C6C8CE;
        border-left: 1px solid #c6c8ce;
        gap: 8px;



        .controlBarType {
            display: flex;
            flex-direction: row;
            height: 36px;
            padding: 0px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background: rgba(35, 79, 220, 0.15);
            color: #234FDC;

            img {
                height: 20px;
                width: 20px;
            }

            &:hover {
                cursor: pointer;
                background-color: #476CE2;
                color: #FFFFFF !important;
            }


            .typeIconDiv {

                .typeIconCross {
                    display: flex;
                    height: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            .typeText {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .controlBarBottom {
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 8px;

        .showActionButtons {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }
}

.exitConderence {
    .modal-content {
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14) !important;
        border-radius: 8px !important;
        border: 1px solid #C6C8CE !important;
        padding: 16px !important;
        background-clip: unset !important;
        max-width: 600px;
        width: 500px;

        .modal-header {
            display: flex;
            justify-content: space-between;
            padding: 0px;
            border-bottom: 0px;
            margin-bottom: 8px;
            color: #1F2747;
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;

            .modal-close-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
                width: 36px;
                cursor: pointer;

                &:hover {
                    border-radius: 8px;
                    background: #F3F4F5;
                }

                .custom-close-btn {
                    height: 16px !important;
                    width: auto !important;
                    padding: 11px 15px !important;
                }
            }
        }

        .modal-body {
            padding: 0px !important;
            color: #1F2747;
            margin-bottom: 24px;

            .input-wrapper:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .modal-footer {
            padding: 0px !important;
            border-top: none !important;
        }

    }
}

.fontAwesomeCustomIcon {

    width: 16px !important;
    height: 16px !important;

}