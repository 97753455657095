.overallHeaderDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 528px;

    .overallHeaderInnerDiv {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0px;
        align-items: flex-start;
        width: 460px;


        .headerDiv {
            display: flex;
            text-align: left;
            align-items: center;
            font-weight: 600;
            font-size: 20px;
            color: #1F2747;
            line-height: 24px;

        }

        .textDiv {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            align-items: center;
            color: #4B5168;
            // text-align: justify;

        }
    }

    .userCloseDiv {
        width: 36px !important;
        height: 36px !important;

        gap: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 40px;
        /* border: 1px solid; */
        justify-content: center;
        align-items: center;


        &:hover {
            background-color: #F3F4F5;
            border-radius: 8px;
            cursor: pointer;

        }


        .userCloseInnerDiv {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 20px;
            height: 20px;
            /* border: 1px solid; */
        }

        .userCloseInnerDivIcon {
            width: 10px;
            height: 16px;
            font-weight: 400;
            font-size: 16px;
            display: flex;
            color: #1F2747;
            line-height: 16px;
            align-items: center;
            text-align: center;
        }
    }
}